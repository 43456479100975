import * as React from "react";
import styled from "styled-components";
import "normalize.css";
import GlobalStyles from "../../styles/GlobalStyles";
import { Navigation } from "../Navigation";
import { Footer } from "../Footer";

const Container = styled.div`
  max-width: 800px;
  padding: 15px;
  margin: 0 auto 60px;
  text-align: center;
`;

export const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Navigation />
      <Container>{children}</Container>
      <Footer />
    </>
  );
};

export default Layout;

import * as React from "react";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import a11yicon from "../assets/images/bitesizeda11y.svg";
const Icon = styled.img`
  height: 100px;
  width: 100px;
`;

const BrandLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BrandLink = styled.a`
  display: block;
  font-family: "Gilroy", sans-serif;
  font-size: 1.2rem;
  color: var(--highlight);
  text-decoration: none;
  margin: 20px auto 40px;
  text-align: center;
  flex: 1 0 auto;
  overflow: hidden;
  font-weight: bold;
  padding: 5px;
`;

const H1 = styled.h1`
  font-size: 3.5rem;
  margin: 2rem auto 2.5rem;
`;

const ThankYouPage = () => {
  return (
    <main>
      <Layout>
        <BrandLinkWrapper>
          <BrandLink href="/">a11y examples</BrandLink>
        </BrandLinkWrapper>
        <Icon
          src={a11yicon}
          alt="a11y examples logo with a bite taken out of it"
        />
        <H1>Almost there...</H1>
        <p>Now, please check your email to confirm your subscription.</p>
      </Layout>
    </main>
  );
};

export default ThankYouPage;
